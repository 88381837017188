<template>
  <div class="galaxy">
    <div class="sizeme">
      <trailerMenu/>
      <div v-if="mobileView" class="imagelayoutstyle">
        <div class="imagelayoutsingle">
          <img class="images" src="../../assets/trailers/TG.png"/>
        </div>
      </div>
      <p>
        The Woodford Galaxy comes in two models, Galaxy18 and Galaxy20.
        The Galaxy is built by professionals for professionals and the choice of both their
        logistics and motorsport clients. Their signature fully covered trailer is semi-aluminium
        and designed especially for professional commercial users.
      </p>
      <p>For more information and prices please refer to the table below, also there is a diagram for reference purposes.</p>
      <div class="qutoes">
        <table>
          <tbody>
            <caption style="font-weight: bold;">The Galaxy specifications & prices (Measurements are in millimetres)</caption>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
          <tbody>
            <caption style="font-weight: bold;">
                OH: Overall Height, OL: Overall Length, OW: Overall Width,
                RL: Ramp Length, FSS: Fort storage space, GW: Gross weight,
                LW: Load weight
            </caption>
          </tbody>
        </table>
      </div>
      <div class="bodystyle">
        <div class="align-left">
          <p style="margin-bottom:20px;">As standard with all Galaxy trailers:</p>
          <p>&#x2022; Electric Hydraulic Tilt Bed</p>
          <p style="margin-top: -10px;">&#x2022; Strong Chassis with upgraded axles</p>
          <p style="margin-top: -10px;">&#x2022; Choice of 2 different widths, heights and lengths</p>
          <p style="margin-top: -10px;">&#x2022; Half aluminium chassis with honeycomb floor</p>
          <p style="margin-top: -10px;">&#x2022; Internal strip LED and two sensor lights</p>
          <p style="margin-top: -10px;">&#x2022; Two front and two rear hatch doors</p>
          <p style="margin-top: -10px;">&#x2022; Two main 2.5 Metre doors with centre opening</p>
          <p style="margin-top: -10px;">&#x2022; Under floor storage section</p>
          <p style="margin-top: -10px;">&#x2022; Wheel Chock</p>
          <p style="margin-top: -10px;">&#x2022; Alloy wheels and two spare wheels</p>
          <p style="margin-top: -10px;">&#x2022; Shock Absorbers</p>
          <p style="margin-top: -10px;">&#x2022; Stainless Tailgate with heavy duty clams and locks</p>
          <p style="margin-top: -10px;">&#x2022; Rear supporting rollers instead of skids</p>
          <p style="margin-top: -10px;">&#x2022; Set of 4 heavy duty straps and hooks</p>
          <p style="margin-top: -10px;">&#x2022; 9500LB short drum electric winch with nylon cable</p>
          <p style="margin-top: -10px;">&#x2022; Stabiliser ALKO Coupling</p>
          <p style="margin-top: -10px;">&#x2022; Assistor ramps allowing 1.5 degree loading angle</p>
          <p style="margin-top: -10px;">&#x2022; Aluminium full length 600mm double tie down rails</p>
        </div>
        <div class="align-right">
          <p>Diagram for reference purposes only.</p>
          <div class="imagelayoutsingle">
            <img class="images" src="../../assets/trailers/galaxy/axle3.png"/>
          </div>
        </div>
      </div>
      <image-slider v-if="mobileView" :listImages="gallery"/>
      <image-wall v-if="!mobileView" v-bind:list-images="gallery"/>
    </div>
  </div>
</template>

<script>
import { getImages, getPrices } from '../../store/helper.js'
import ImageWall from '../../components/images/ImageWall.vue'
import ImageSlider from '../../components/images/ImageSlider.vue'
import trailerMenu from '../../components/trailerMenu.vue'

export default {
  name: 'galaxy',
  components: {
    ImageWall,
    ImageSlider,
    trailerMenu
  },
  data () {
    return {
      mobileView: true,
      gallery: [],
      priceList: []
    }
  },
  mounted () {
    this.getPriceList()
  },
  created () {
    this.getGallery()
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 500
    },
    getGallery () {
      const vm = this
      vm.gallery = getImages('galaxy')
    },
    getPriceList () {
      const vm = this
      vm.priceList = getPrices('1UPlJhb2mfo9BwzXHyxXSOe3l7TubqXO0WpSramArews', 'A3:K5')
    }
  }
}
</script>

<style lang="scss" scoped>

.imagelayoutstyle {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    margin-top: 10px;
    margin-left: 0px;
    flex-direction: column;
  }
}

.imagelayoutsingle {
  @media (max-width: 760px) {
    margin-top: -6px;
    height: auto;
    width: 100%;
  }
}

.images {
  border: .2px solid black;
  height: 220px;
  width: 340px;
  @media (max-width: 760px) {
    height: auto;
    width: 100%;
  }
}

.bodystyle {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 760px) {
    margin-top:-50px;
    flex-direction: column;
    width: 100%;
  }
}
.qutoes {
  text-align: center;
  width:76%;
  margin-left:12%;
  margin-right:12%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 760px) {
    width:100%;
    margin-left:0%;
    margin-right:0%;
    margin-bottom: 45px;
  }
}

.align-left {
  width: 40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

.align-right {
  width: -40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  background-color: #f6f6f6;
}/* Spacing */
td, th {
  border: 1px solid #999;
  padding: 10px;
}
caption, th {
  background: grey;
  color: white;
  border-radius: 0;
  position: sticky;
  top: 0;
  padding: 10px;
}
.primary{
  background-color: #000000
}

tfoot > tr  {
  background: black;
  color: white;
}
tbody > tr:hover {
  background-color: #ffc107;
}

table {
  @media (max-width: 760px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

table tbody {
  display: table;
  min-width: 1100px;
}

.sizeme {
  width: 85%;
  margin: auto;
}

</style>
