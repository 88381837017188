<template>
  <div id="menu">
    <div v-if="!mobileView">
      <logo/>
      <!-- <h1 style="margin-top:-20px">Mo's Vehicle Transport</h1> -->
      <div id="desktopMenu">
        <router-link to="/">Home</router-link> |
        <!-- <router-link to="/transport">Transport</router-link> | -->
        <router-link to="/trailers">Trailers</router-link> |
        <!-- <router-link to="/quote-us">Get Quote</router-link> | -->
        <router-link to="/about-us">About Us</router-link> |
        <router-link to="/contact-us">Contact Us</router-link>
        <!-- <router-link to="/testimony">Reviews</router-link> | -->
        <!-- <router-link to="/admin">Admin</router-link> -->
      </div>
    </div>
    <div v-if="mobileView">
      <div id="navbarHeight" class="mobileMenu">
        <logo id="navbarLogo"/>
        <!-- <h3 id="navbarMessage" class="titlePresent" v-on:click="homePage()">Mo's Vehicle Transport</h3> -->
        <div>
          <Slide width="250" :closeOnNavigation="true">
            <a id="home">
              <router-link to="/">Home</router-link>
            </a>
            <!-- <a id="transport">
              <router-link to="/transport">Transport</router-link>
            </a> -->
            <a id="trailers">
              <router-link to="/trailers">Trailers</router-link>
            </a>
             <!-- <a id="quote-us">
              <router-link to="/quote-us">Get Quote</router-link>
            </a> -->
            <a id="about-us">
              <router-link to="/about-us">About Us</router-link>
            </a>
            <a id="contact-us">
              <router-link to="/contact-us">Contact Us</router-link>
            </a>
            <!-- <a id="testimony">
              <router-link to="/testimony">Reviews</router-link>
            </a> -->
            <!-- <a id="admin">
              <router-link to="/admin">Admin</router-link>
            </a> -->
            <Socialmedia />
          </Slide>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import logo from '../components/logo/logo.vue'
import { Slide } from 'vue-burger-menu'
import Socialmedia from './Socialmedia.vue'

export default {
  components: {
    logo,
    Slide, // Register your component
    Socialmedia
  },
  data: () => {
    return {
      mobileView: true
    }
  },
  // mounted () {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    homePage () {
      this.$router.push(
        { name: 'Home' }
      ).catch(() => {})
    },
    handleView () {
      this.mobileView = window.innerWidth <= 500
    },
    handleScroll () {
      // if (document.body.scrollTop > 15 || document.documentElement.scrollTop > 15) {
      //   if (document.getElementById('navbarLogo')?.style) {
      //     document.getElementById('navbarLogo').style.visibility = 'hidden'
      //   }
      //   if (document.getElementById('navbarMessage')?.style) {
      //     document.getElementById('navbarMessage').style.visibility = 'visible'
      //   }
      //   if (document.getElementById('navbarHeight')?.style) {
      //     document.getElementById('navbarHeight').style.height = '55px'
      //   }
      // } else {
      //   if (document.getElementById('navbarLogo')?.style) {
      //     document.getElementById('navbarLogo').style.visibility = 'visible'
      //   }
      //   if (document.getElementById('navbarMessage')?.style) {
      //     document.getElementById('navbarMessage').style.visibility = 'hidden'
      //   }
      //   if (document.getElementById('navbarHeight')?.style) {
      //     document.getElementById('navbarHeight').style.height = '125px'
      //   }
      // }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../assets/common.scss";

#desktopMenu {
  padding: 5px;

  a {
    text-decoration: none;
    font-weight: bold;
    color: $content-colour;

    &.router-link-exact-active {
      color: $active-colour;
      cursor: pointer;
    }
  }
}

.mobileMenu {
  height: 125px;
  left: 0px;
  right: 0px;
  top: 0px;
  position: fixed;
  background: white;
  z-index: 1000;

  a {
    font-weight: bold;
    color: $inactive-colour;
    text-decoration:none;
    white-space: nowrap;

    &.router-link-exact-active {
      color: $active-colour;
    }
  }
}

// .titlePresent {
//   visibility: hidden;
//   left: 0px;
//   right: 0px;
//   top: 0px;
//   position: fixed;

// }

.content {
  @media (max-width: 500px) {
    margin-top: 125px;
  }
}

/deep/ .bm-burger-button {
  left: 18px;
  top: 18px;
}
</style>
