<template>
  <div class="flatbed">
    <div class="sizeme">
      <trailerMenu/>
      <div v-if="mobileView" class="imagelayoutstyle">
        <div class="imagelayoutsingle">
          <img class="images" src="../../assets/trailers/TAC.png"/>
        </div>
      </div>
      <p>
        The Woodford Auto Cruiser is the newest addition to their prestige open car trailer range.
        The advantages of the Auto Cruiser Trailer it’s lightweight chassis – they have two models
        available with variant bed lengths and a choice of axles. They are constantly looking to
        innovate with their trailer range so they are excited that the Auto Cruiser sets new standards
        in trailer engineering. The quality and strength of the chassis and components make this
        trailer the perfect choice for all your transportation needs.
      </p>
       <p>
          Woodford Auto Cruiser trailers are high quality aluminium trailers ideal for all vehicle
          haulage, the newest open car trailer available as a standard or lightweight model with
          two or three axles and three variant bed lengths from 4290mm to 5500mm. With a wide range
          of sizes and configurations we are certain we have the right trailer to suit you. All Auto
          Cruisers come with LED Lights, spare Wheel, punched 500mm wide deck runners full length or
          full width floor, rails 80mm wide each side of chassis with strapping points every 300mm,
          set of loading ramps 2.4m long, storage box 700mm long x 182mm wide x 235mm height, 50mm
          ball coupling with barrel lock and safety ball and supporting loading legs. For all the
          different models of the Auto Cruiser please see the full specifications below.
        </p>
      <p>For more information and prices please refer to the table below, also there is a diagram for reference purposes.</p>
      <div class="qutoes">
        <table>
          <tbody>
            <caption style="font-weight: bold;">The Auto Cruiser specifications & Prices (Measurements are in millimetres)</caption>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
          <tbody>
            <caption style="font-weight: bold;">
              BH: Bed Height, OL: Overall Length, OW: Overall Width,
              GW: Gross weight, LW: Load weight
            </caption>
          </tbody>
        </table>
      </div>
      <div class="bodystyle">
        <div class="align-left">
          <p>Diagram for reference purposes only.</p>
          <div class="imagelayoutsingle">
            <img class="images" src="../../assets/trailers/galaxy/Open3Axle.png"/>
          </div>
        </div>
        <div class="align-right">
          <p>Diagram for reference purposes only.</p>
          <div class="imagelayoutsingle">
            <img class="images" src="../../assets/trailers/galaxy/Open2Axle.png"/>
          </div>
        </div>
      </div>
      <p>
        All Auto cruiser models come with optional extras to build that perfect trailer you are after,
        below you find all the extras that are offered for the Auto Cruiser models.
        Any questions please do feel free to contact us.
      </p>
      <div class="qutoes2">
        <table>
          <tbody style="min-width: 800px;">
            <caption style="font-weight: bold;">FOR STANDARD AND LIGHT MODELS</caption>
            <tr v-for="(items, i) in extraList[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in extraList[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
          <tbody style="min-width: 800px;">
            <caption style="font-weight: bold;">FOR STANDARD ONLY MODELS</caption>
            <tr v-for="(items, i) in extraList2[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in extraList2[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <image-slider v-if="mobileView" :listImages="gallery"/>
      <image-wall v-if="!mobileView" v-bind:list-images="gallery"/>
    </div>
  </div>
</template>

<script>
import { getImages, getPrices } from '../../store/helper.js'
import ImageWall from '../../components/images/ImageWall.vue'
import ImageSlider from '../../components/images/ImageSlider.vue'
import trailerMenu from '../../components/trailerMenu.vue'

export default {
  name: 'galaxy',
  components: {
    ImageWall,
    ImageSlider,
    trailerMenu
  },
  data () {
    return {
      mobileView: true,
      gallery: [],
      priceList: [],
      extraList: [],
      extraList2: []
    }
  },
  mounted () {
    this.getPriceList()
  },
  created () {
    this.getGallery()
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 500
    },
    getGallery () {
      const vm = this
      vm.gallery = getImages('auto')
    },
    getPriceList () {
      const vm = this
      vm.priceList = getPrices('1uoljmvRdarawyV3Y9Qv3TP7LGgSHSDjxvCgejh9NDmQ', 'B3:K11')
      vm.extraList = getPrices('1eg66GXy39Y7ItnpiNsEMhNUiELuILWq_ctZxjAMcaT4', 'B4:D17')
      vm.extraList2 = getPrices('1eg66GXy39Y7ItnpiNsEMhNUiELuILWq_ctZxjAMcaT4', 'B19:D22')
    }
  }
}
</script>

<style lang="scss" scoped>

.imagelayoutstyle {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    margin-top: -5px;
    margin-left: 0px;
    flex-direction: column;
  }
}

.imagelayoutsingle {
  @media (max-width: 760px) {
    margin-top: 10px;
    height: auto;
    width: 100%;
  }
}

.images {
  border: .2px solid black;
  height: 220px;
  width: 340px;
  @media (max-width: 760px) {
    height: auto;
    width: 100%;
  }
}

.bodystyle {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 760px) {
    margin-top:-10px;
    flex-direction: column;
    width: 100%;
  }
}
.qutoes {
  text-align: center;
  width:76%;
  margin-left:12%;
  margin-right:12%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 760px) {
    width:100%;
    margin-left:0%;
    margin-right:0%;
    margin-bottom: 45px;
  }
}

.qutoes2 {
  text-align: center;
  width:58%;
  margin-left:21%;
  margin-right:21%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 760px) {
    width:100%;
    margin-left:0%;
    margin-right:0%;
    margin-bottom: 45px;
  }
}
.align-left {
  width: 40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

.align-right {
  width: -40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  background-color: #f6f6f6;
}/* Spacing */
td, th {
  border: 1px solid #999;
  padding: 10px;
}
caption, th {
  background: grey;
  color: white;
  border-radius: 0;
  position: sticky;
  top: 0;
  padding: 10px;
}
.primary{
  background-color: #000000
}

tfoot > tr  {
  background: black;
  color: white;
}
tbody > tr:hover {
  background-color: #ffc107;
}

table {
  @media (max-width: 760px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

table tbody {
  display: table;
  min-width: 1100px;
}

.sizeme {
  width: 85%;
  margin: auto;
}

</style>
