<template>
  <div id="app">
    <Menu/>
    <Socialmedia v-if="!mobileView" />
    <div v-if="mobileView">
      <ContactFooter v-if="!this.$store.state.formFocus"/>
    </div>
    <!-- <ContactFooter v-if="mobileView"/> -->
  </div>
</template>

<script>
import Menu from './components/menu.vue'
import Socialmedia from './components/Socialmedia.vue'
import ContactFooter from './components/ContactFooter.vue'

export default {
  components: {
    Menu,
    Socialmedia,
    ContactFooter
  },
  data: () => {
    return {
      mobileView: true
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 500
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}

</script>

<style lang="scss">
@import "assets/common.scss";
@import url("https://use.fontawesome.com/releases/v5.9.0/css/all.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $content-colour;
  background: #fff;
}
button {
  cursor: pointer;
}

input:focus, textarea:focus, button:focus {
  outline: none;
  box-shadow: none;
}

.button-icon {
  background-color: $media-colour;
  font-size: 1.5rem;
  text-decoration:none;
  border: none;
  color: $inactive-colour;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 8px;
  width: 30%;
  cursor: pointer;

  @media (max-width: 760px) {
    font-size: 1rem;
    width: 45%;
  }
}

.button-icon:hover {
  background-color: #b5c9c9;
}

</style>
