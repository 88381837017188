<template>
  <div class="card-body">
      <form id="form" @submit.prevent="sentEmail">
        <div class="form-group">
          <label style="margin-top: 15px;">Full Name:</label>
          <input
            required
            type="text"
            v-model="form.name"
          />
          <label>Email Address:</label>
          <input
            required
            type="email"
            v-model="form.email_address"
          />
          <label>Telephone Number:</label>
          <input
            required
            type="text"
            v-model="form.telephone_number"
          />
          <label>Message:</label>
          <textarea
            required
            wrap="hard"
            style="resize: none;"
            placeholder="Add description here"
            v-model="form.comments"
          >
          </textarea>
        </div>
        <div class="form-group">
          <input
            type="submit"
            class="button-icon"
            value="Submit Request"
          />
        </div>
      </form>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  data () {
    return {
      form: {
        name: '',
        email_address: '',
        telephone_number: '',
        comments: ''
      }
    }
  },
  mounted () {
    const form = document.getElementById('form')

    form.addEventListener('focus', (event) => {
      // this.$store.state.formFocus = true
      this.$store.commit('checkFormFocus', true)
      // event.target.style.background = 'pink'
    }, true)

    form.addEventListener('blur', (event) => {
      // this.$store.state.formFocus = false
      this.$store.commit('checkFormFocus', false)
      // event.target.style.background = ''
    }, true)
  },
  methods: {
    log () {
      console.log(this.form)
    },
    notication (text, icon) {
      Swal.fire({
        title: text,
        icon: icon,
        timer: 3000,
        showConfirmButton: false
      })
    },
    sentEmail () {
      const vm = this
      this.$loadScript('https://smtpjs.com/v3/smtp.js')
        .then(() => {
          window.Email && window.Email.send({
            SecureToken: '3d7894bf-9a18-4ab6-aec4-5ce4ff1c3873',
            From: 'Info@mochitrailers.co.uk ',
            To: 'Info@mochitrailers.co.uk ',
            Subject: 'Contact Us',
            Body: `<p>Hello Mo.</P
            <p>${this.form.comments}</p>
            <p>${this.form.name}</p>
            <p>${this.form.telephone_number}</p>
            <p>${this.form.email_address}</p>`
          })
            .then(function (message) {
              vm.form = {}
              vm.notication("Thank you for getting in touch with Mo's Vehicle Transport", 'success')
            })
        })
        .catch((e) => {
          vm.notication('Error has occcured', 'error')
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./form.scss";

</style>
