<template>
  <div class="trailers">
    <div class="sizeme">
      <trailerMenu/>
      <h2>Our trailer collection</h2>
      <p>
        Mochi Trailers is glad to be approved by Woodford trailers to be one of their dealers.
      </p>
      <p>
        We would be offering a wide range of trailers from open car trailer to enclosed car trailers, have a
        browse through the collection of trailer we would be offering, please keep in mind that we only deal
        custom-built trailers, so all trailers are made to order with wait list between 4 to 6 weeks.
      </p>
      <p><b>We stand behind the products we offer.</b></p>
      <p>
        We provide a 5 year chassis warranty, a 1 year warranty for lamps and a 2 year warranty for running
        gear (axles and couplings). We also provide you with the necessary documentation and instructions
        for your trailer.
      </p>
      <h2>Financing options are available on models get in touch and see how we can help!</h2>
      <div class="imagelayoutstyle">
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TAC.png" v-on:click="AutoCruiser()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TF.png" v-on:click="Flatbed()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TG.png" v-on:click="Galaxy()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TL.png" v-on:click="Lightweight()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TRL5.png" v-on:click="RL5000()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TRL76.png" v-on:click="RL7000()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TS.png" v-on:click="Standard()"/>
        </div>
        <div class="imagelayoutsingle">
          <img class="images" src="../assets/trailers/TW.png" v-on:click="Widebody()"/>
        </div>
      </div>
    </div>
    <div class="button-spacing">
      <button
        id="contactUs"
        class="button-icon"
        v-on:click="slideContact"
      >Contact Us
      </button>
    </div>
    <contact-form id='contact' v-if="contactClick"/>
  </div>
</template>

<script>
import ContactForm from '../components/forms/contactForm.vue'
import trailerMenu from '../components/trailerMenu.vue'

export default {
  components: {
    ContactForm,
    trailerMenu
  },
  data () {
    return {
      contactClick: false
    }
  },
  methods: {
    AutoCruiser () {
      this.$router.push(
        { name: 'AutoCruiser' }
      ).catch(() => {})
    },
    Lightweight () {
      this.$router.push(
        { name: 'Lightweight' }
      ).catch(() => {})
    },
    Flatbed () {
      this.$router.push(
        { name: 'Flatbed' }
      ).catch(() => {})
    },
    Galaxy () {
      this.$router.push(
        { name: 'Galaxy' }
      ).catch(() => {})
    },
    RL7000 () {
      this.$router.push(
        { name: 'RL7000' }
      ).catch(() => {})
    },
    RL5000 () {
      this.$router.push(
        { name: 'RL5000' }
      ).catch(() => {})
    },
    Standard () {
      this.$router.push(
        { name: 'Standard' }
      ).catch(() => {})
    },
    Widebody () {
      this.$router.push(
        { name: 'Widebody' }
      ).catch(() => {})
    },
    slideContact () {
      if (this.contactClick === false) {
        this.contactClick = true
        this.quoteClick = false
        setTimeout(
          function () {
            document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })
          }, 500
        )
      } else if (this.contactClick === true) {
        this.contactClick = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../assets/common.scss";

.sizeme {
  width: 85%;
  margin: auto;
}

.imageborder {
  box-sizing: border-box;
  border: 1px solid #2c3e50;
}

body {
  background: #fff;
  padding-bottom: 5px;
  @media (max-width: 760px) {
    margin: 0;
    padding-bottom: 75px;
  }
}

a {
    text-decoration: none;
    color: $content-colour;
  }

.button-spacing {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

  @media (min-width: 760px) {
    width: 60%;
  }
}

.imagelayoutstyle {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    margin-top: -5px;
    margin-left: 0px;
    flex-direction: column;
  }
}

.imagelayoutsingle {
  @media (max-width: 760px) {
    margin-top: -6px;
    height: auto;
    width: 100%;
  }
}

.images {
  cursor: pointer;
  border: .2px solid black;
  height: 220px;
  width: 340px;
  @media (max-width: 760px) {
    height: auto;
    width: 100%;
  }
}

.sizeme {
  width: 85%;
  margin: auto;
}

</style>
