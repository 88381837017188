<template>
  <div id="contactfooter" class="contact-media">
    <div>
      <a
        class="fas fa-phone-alt"
        :href="`tel:+${whatsapp}`"
      >
      </a>
      <p>Phone</p>
    </div>
    <div>
      <a
        class="fab fa-whatsapp"
        :href="`//api.whatsapp.com/send?phone=${whatsapp}&text=${message}`"
        target="_blank"
      >
      </a>
      <p>Whatsapp</p>
    </div>
    <div>
      <a
        class="fab fa-facebook-messenger"
        href="https://m.me/166466386560461"
        target="_blank"
      >
      </a>
      <p>Messenger</p>
    </div>
    <div>
      <a
        class="fas fa-envelope"
        v-on:click="email()"
      >
      </a>
      <p>Email</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'contactfooter',
  data () {
    return {
      files: '',
      whatsapp: 447776095424,
      message: 'Hi Mo, I Am messaging you today regarding trailers',
      emailAddress: 'Info@mochitrailers.co.uk ',
      subject: 'Trailer Inquire',
      body: 'Hi Mo, %0A Give me info on trailers %0A'
    }
  },
  methods: {
    email () {
      window.open(`mailto:${this.emailAddress}?subject=${this.subject}&body=${this.body}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/common.scss";

.contact-media {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    margin: 0 10px 0 0;
    color: $media-colour;
    font-size: 2rem;
    text-decoration:none;
  }
  p {
    margin: 0 10px 0 0;
    color: $media-colour;
    font-size: 1rem;
    text-decoration:none;
  }
}

</style>
