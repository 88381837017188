import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXVmJ-JmcmAe6aTnPpu1kQ7a98fAd55CM",
  authDomain: "mo-trailers.firebaseapp.com",
  projectId: "mo-trailers",
  storageBucket: "mo-trailers.appspot.com",
  messagingSenderId: "826308367966",
  appId: "1:826308367966:web:6b94d312899f803f9006cf",
  measurementId: "G-T7DYG48X4S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const firestore = app.firestore();
const storing = getStorage();

export default { storing, app, analytics }
