<template>
  <div class="aboutUs" style="min-height:300px;">
    <div class="sizeme">
      <h2>Get In Touch with Us</h2>
      <p>
        If you have any questions about any of the trailers or want to know more about Mochi Trailers,
        please do not hesitate to get in contact with us. We have a wide variety of ways getting in touch,
        there is by phone, WhatsApp, messenger, email or by filling the form below.
    </p>
    <p>Phone Number: 0777 6095424</p>
    <p>Email: Info@mochitrailers.co.uk </p>
    </div>
    <div class="contact-media">
      <div>
        <a
          class="fab fa-facebook-messenger"
          href="https://m.me/166466386560461"
          target="_blank"
        >
        </a>
        <p>Messenger</p>
      </div>
      <div>
        <a
          class="fab fa-whatsapp"
          :href="`//api.whatsapp.com/send?phone=${whatsapp}&text=${message}`"
          target="_blank"
        >
        </a>
        <p>WhatsApp</p>
      </div>
    </div>
    <contact-form/>
  </div>
</template>

<script>
import db from '../../config/db'
import ContactForm from '../components/forms/contactForm.vue'
import Swal from 'sweetalert2'

export default {
  name: 'aboutUs',
  components: {
    ContactForm
  },
  data () {
    return {
      whatsapp: 447776095424,
      message: 'Hi Mo, I Am messaging you today regarding transport for ',
      testimony: [],
      testClick: false,
      newtestimony: {
        name: '',
        location: '',
        description: '',
        imagename: ''
      },
      token: '',
      files: '',
      pageOfItems: []
    }
  },
  mounted () {
    this.getDB()
    this.$store.commit('checkENV')
  },
  methods: {
    toggleClick () {
      if (this.testClick === false) {
        this.testClick = true
      } else if (this.testClick === true) {
        this.testClick = false
      }
    },
    checkToken () {
      const vm = this
      db.firestore
        ?.collection('token' + this.$store.state.env)
        .doc('access')
        .get()
        .then(function (doc) {
          if (vm.token === doc.data().token) {
            vm.addDB()
            vm.storeFiles()
            vm.notication('Success', 'success')
            vm.testClick = false
            vm.newtestimony = {}
            vm.token = ''
          } else {
            vm.notication('Invalid Token', 'error')
          }
        })
    },
    addDB () {
      const vm = this
      if (this.$refs.myFiles.files.length === 1) {
        this.newtestimony.imagename = this.$refs.myFiles.files[0].name
      } else {
        this.newtestimony.imagename = 'no-image.png'
      }
      db.firestore?.collection('/testimony' + this.$store.state.env).add({
        name: this.newtestimony.name,
        location: this.newtestimony.location,
        Description: this.newtestimony.description,
        imageName: this.newtestimony.imagename,
        timestamp: new Date()
      }).then(setTimeout(() => { vm.getDB() }, 2000))
    },
    storeFiles () {
      if (this.newtestimony.imagename !== 'no-image.png') {
        const fileInfo = this.$refs.myFiles.files[0]
        db.storing.ref(
          'images' + this.$store.state.env + '/' +
          fileInfo.name
        ).put(fileInfo)
      }
    },
    getDB () {
      const vm = this
      let testimonyinfo = []
      let data = {}
      db.firestore?.collection('testimony' + this.$store.state.env)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // Using image name to obtain image url
            db.storing.ref('images' + this.$store.state.env + '/')
              .child(doc.data().imageName)
              .getDownloadURL()
              .then((downloadURL) => {
                data = {
                  id: doc.id,
                  name: doc.data().name,
                  location: doc.data().location,
                  description: doc.data().Description,
                  imageURL: downloadURL,
                  timestamp: doc.data().timestamp
                }
                testimonyinfo.push(data)
                testimonyinfo = testimonyinfo.sort(
                  (a, b) => b.timestamp - a.timestamp
                )
              })
          })
        })
      vm.testimony = testimonyinfo
    },
    notication (text, icon) {
      Swal.fire({
        title: text,
        icon: icon,
        timer: 1500,
        showConfirmButton: false
      })
    },
    onChangePage (pageOfItems) {
      this.pageOfItems = pageOfItems
      // window.scrollTo(0, 150)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../assets/common.scss";

.sizeme {
  width: 85%;
  margin: auto;
}

.contact-media {
  background-color: white;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    margin: 0 10px 0 0;
    color: $media-colour;
    font-size: 2rem;
    text-decoration:none;
  }

  p {
    margin: 0 10px 0 0;
    color: $media-colour;
    font-size: 1rem;
    text-decoration:none;
  }
}

</style>
