<template>
  <div id="Socialmedia">
    <div class="social-media">
      <a
        href="https://www.facebook.com/MochiTrailers/"
        target="_blank"
      >
        <img class="imageSize" src="../assets/facebook.png"/>
      </a>
      <a
        href="https://www.instagram.com/mochitrailers/"
        target="_blank"
      >
        <img class="imageSize" src="../assets/instagram.png"/>
      </a>
    </div>
  </div>
</template>

<script>
import db from '../../config/db'

export default {
  name: 'media',
  data () {
    return {
      files: ''
    }
  },
  methods: {
    storeFiles () {
      const fileInfo = this.$refs.myFiles.files[0]
      db.storing.ref(
        'camera/' +
        fileInfo.name.split('.')[0] +
        '-' +
        fileInfo.lastModified
      ).put(fileInfo)
    }
  }
}
</script>

<style lang="scss">
@import "../assets/common.scss";

.social-media {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 160px 160px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  // margin-bottom: 20px;

  @media (max-width: 500px) {
    display: flex;
  }

  // a {
  //   margin: 0 10px 0 0;
  //   color: $media-colour;
  //   font-size: 2.5rem;
  //   text-decoration:none;
  // }
}

.imageSize {
  width: 50%;

  @media (max-width: 500px) {
    width: 80%;
    margin-left: -40px;
  }
}

</style>
