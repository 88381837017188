<template>
  <div class="lightweight">
    <div class="sizeme">
      <trailerMenu/>
      <div v-if="mobileView" class="imagelayoutstyle">
        <div class="imagelayoutsingle">
          <img class="images" src="../../assets/trailers/TL.png"/>
        </div>
      </div>
      <p>
        The Woodford Lightweight trailer range is ideal for transporting small lightweight vehicles and is commonly
        used by motorsport enthusiasts. Ideal for cars up to 1500kg, with options of single or twin braked axle.
        They are extremely robust and light and like all their trailer range are very user friendly.
        The Lightweight trailers are available in a wide range specification.
      </p>
      <p>For more information and prices please refer to the table below, also there is a diagram for reference purposes.</p>
      <div class="qutoes">
        <table>
          <tbody>
            <caption style="font-weight: bold;">The Lightweight trailers for Smart cars specifications & Prices (Measurements are in millimetres)</caption>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in priceList[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
          <tbody>
            <caption style="font-weight: bold;">The Lightweight trailers specifications & Prices (Measurements are in millimetres)</caption>
            <tr v-for="(items, i) in priceList2[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in priceList2[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
          <tbody>
            <caption style="font-weight: bold;">
              OL: Overall Length, OW: Overall Width, RL: Ramp Length,
              GW: Gross weight, LW: Load weight
            </caption>
          </tbody>
        </table>
      </div>
      <div class="bodystyle">
        <div class="align-left">
          <p>Diagram for reference purposes only.</p>
          <div class="imagelayoutsingle">
            <img class="images" src="../../assets/trailers/galaxy/LT1Axle.png"/>
          </div>
        </div>
        <div class="align-right">
          <p>Diagram for reference purposes only.</p>
          <div class="imagelayoutsingle">
            <img class="images" src="../../assets/trailers/galaxy/LT2Axle.png"/>
          </div>
        </div>
      </div>
      <p>
        All Lightweight models come with optional extras to build that perfect trailer you are after,
        below you find all the extras that are offered for the Lightweight models.
        Any questions please do feel free to contact us.
      </p>
      <div class="qutoes2">
        <table>
          <tbody style="min-width: 800px;">
            <caption style="font-weight: bold;">The Lightweight optional extras </caption>
            <tr v-for="(items, i) in extraList[0]" :key="i + 'a'">
              <template v-if="i === 0" >
                <th v-for="(item, i) in items" :key="i + 'b'"> {{ item }} </th>
              </template>
            </tr>
            <tr v-for="(items, i) in extraList[0]" :key="i + 'c'">
              <template v-if="i !== 0" >
                <td v-for="(item, i) in items" :key="i + 'd'"> {{ item }} </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <image-slider v-if="mobileView" :listImages="gallery"/>
      <image-wall v-if="!mobileView" v-bind:list-images="gallery"/>
    </div>
  </div>
</template>

<script>
import { getImages, getPrices } from '../../store/helper.js'
import ImageWall from '../../components/images/ImageWall.vue'
import ImageSlider from '../../components/images/ImageSlider.vue'
import trailerMenu from '../../components/trailerMenu.vue'

export default {
  name: 'galaxy',
  components: {
    ImageWall,
    ImageSlider,
    trailerMenu
  },
  data () {
    return {
      mobileView: true,
      gallery: [],
      priceList: [],
      priceList2: [],
      extraList: []
    }
  },
  mounted () {
    this.getPriceList()
  },
  created () {
    this.getGallery()
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 500
    },
    getGallery () {
      const vm = this
      vm.gallery = getImages('lightweight')
    },
    getPriceList () {
      const vm = this
      vm.priceList = getPrices('1se71LUWRlZESS7BVoay-hgsHj-LFFpj64jdngknLY-E', 'B4:L8')
      vm.priceList2 = getPrices('1se71LUWRlZESS7BVoay-hgsHj-LFFpj64jdngknLY-E', 'B10:L37')
      vm.extraList = getPrices('1wwow-COt2SzoMnInK0fMdm7w_OmGexHpRcQdBaqMCUE', 'B4:D18')
    }
  }
}
</script>

<style lang="scss" scoped>

.imagelayoutstyle {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    margin-top: 10px;
    margin-left: 0px;
    flex-direction: column;
  }
}

.imagelayoutsingle {
  @media (max-width: 760px) {
    margin-top: -6px;
    height: auto;
    width: 100%;
  }
}

.images {
  border: .2px solid black;
  height: 220px;
  width: 340px;
  @media (max-width: 760px) {
    height: auto;
    width: 100%;
  }
}

.bodystyle {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 760px) {
    margin-top:-10px;
    flex-direction: column;
    width: 100%;
  }
}
.qutoes {
  text-align: center;
  width:76%;
  margin-left:12%;
  margin-right:12%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 760px) {
    width:100%;
    margin-left:0%;
    margin-right:0%;
    margin-bottom: 45px;
  }
}

.qutoes2 {
  text-align: center;
  width:58%;
  margin-left:21%;
  margin-right:21%;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 760px) {
    width:100%;
    margin-left:0%;
    margin-right:0%;
    margin-bottom: 45px;
  }
}
.align-left {
  width: 40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

.align-right {
  width: -40%;
  text-align: left;
  @media (max-width: 760px) {
    text-align: center;
    width: 100%;
  }
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
  background-color: #f6f6f6;
}/* Spacing */
td, th {
  border: 1px solid #999;
  padding: 10px;
}
caption, th {
  background: grey;
  color: white;
  border-radius: 0;
  position: sticky;
  top: 0;
  padding: 10px;
}
.primary{
  background-color: #000000
}

tfoot > tr  {
  background: black;
  color: white;
}
tbody > tr:hover {
  background-color: #ffc107;
}

table {
  @media (max-width: 760px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

table tbody {
  display: table;
  min-width: 1100px;
}

.sizeme {
  width: 85%;
  margin: auto;
}

</style>
