<template>
  <div>
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i" class="imageborder">
        <img :src="currentImg" @click="next"/>
      </div>
    </transition-group>
  </div>
</template>
<script>

export default {
  props: {
    listImages: {
      type: Array,
      default: () => null
    }
  },
  data () {
    return {
      timer: null,
      currentIndex: 0
    }
  },

  mounted: function () {
    this.startSlide()
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 5000)
    },

    next: function () {
      this.currentIndex += 1
    }
  },

  computed: {
    currentImg: function () {
      return this.listImages[Math.abs(this.currentIndex) % this.listImages.length]?.image
    }
  }
}

</script>

<style lang="scss" scoped>

.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  opacity: 0;
}

img {
  height:220px;
  width:100%
}

.imageborder {
  height:222.5px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #2c3e50;
}
</style>
