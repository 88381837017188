<template>
  <div class="home">
    <div class="sizeme">
      <p><b>
        The home of custom-built trailerse.
      </b></p>
      <p>
        Mochi Trailers is glad to be approved by Woodford trailers to be one of their dealers.
      </p>
      <p>
        We are offering a wide range of trailers from open car trailer to enclosed car trailers, please keep in
        mind that all trailers are made to order with wait list between 4 to 6 weeks depending on model. if
        you would like to know more about the trailers we offer, please get in contact with us!
      </p>
      <p>
        We are more the happy to answer any inquires you might have.
      </p>
      <h2>Financing is available on all models!</h2>
      <p>
        If you are looking for a custom-built car trailer, you have come to the right place. We offer competitive
        prices and fast delivery, to order your custom-built trailer is easy with these simple steps:
      </p>
      <p><b>Go through our catalogue of the different trailers we offer and see what one works for you</b></p>
      <p><b>Contact us by phone, email or WhatsApp and tell us your requirements and preferences</b></p>
      <p><b>We will send you a quote of your trailer you want built and we can amend it as much as you like</b></p>
      <p><b>Once you are happy with the quote and build, you can confirm your order by making deposit payment</b></p>
      <p><b>We will start building your trailer and keep you updated on the progress</b></p>
      <p><b>
        Once trailer ready we will deliver your trailer to your location and provide you with the necessary documentation
        and instructions on how to use the trailer
      </b></p>
      <p>
        We are confident that you will love your custom-built car trailer and enjoy using it for years to come.
        We are always here to support you and answer any questions you may have. We look forward to
        hearing from you and creating your perfect trailer.
      </p>
    </div>
    <!-- <div class="button-spacing">
      <button
        id="getQuote"
        class="button-icon"
        v-on:click="slideQuote"
      >Get Quote!
      </button>
      <button
        id="contactUs"
        class="button-icon"
        v-on:click="slideContact"
      >Contact Us
      </button>
    </div> -->
    <!-- <quote-form id='quote' v-if="quoteClick"/>
    <contact-form id='contact' v-if="contactClick"/> -->
    <!-- <p><b>This is what our customers say about us!</b></p>
    <review-view :reviews="listReviews" :maxFive="true"/>
    <div class="sizeme">
      <button
        id="getReview"
        class="button-icon"
        v-on:click="reviewPage()"
      >View More
      </button>
      <p><b>Some of the stuff we have moves for our customers!</b></p>
    </div> -->
    <!-- <image-slider v-if="mobileView" :listImages="gallery"/>
    <image-wall v-if="!mobileView" v-bind:list-images="gallery"/> -->
  </div>
</template>

<script>
import db from '../../config/db'
import { getImages } from '../store/helper.js'
import QuoteForm from '../components/forms/quoteForm.vue'
// import reviewView from '../components/review/reviewView.vue'
import ContactForm from '../components/forms/contactForm.vue'
import ImageWall from '../components/images/ImageWall.vue'
import ImageSlider from '../components/images/ImageSlider.vue'

export default {
  name: 'Home',
  components: {
    // reviewView,
    ImageWall,
    ImageSlider,
    QuoteForm,
    ContactForm
  },
  data () {
    return {
      gallery: [],
      listReviews: [],
      mobileView: true,
      quoteClick: false,
      contactClick: false
    }
  },
  mounted () {
    this.getGallery()
  },
  created () {
    this.getReviews()
    this.getGallery()
    this.handleView()
    window.addEventListener('resize', this.handleView)
  },
  methods: {
    reviewPage () {
      this.$router.push(
        { name: 'Testimony' }
      ).catch(() => {})
    },
    slideQuote () {
      if (this.quoteClick === false) {
        this.quoteClick = true
        this.contactClick = false
        setTimeout(
          function () {
            document.getElementById('quote').scrollIntoView({ behavior: 'smooth' })
          }, 500
        )
      } else if (this.quoteClick === true) {
        this.quoteClick = false
      }
    },
    slideContact () {
      if (this.contactClick === false) {
        this.contactClick = true
        this.quoteClick = false
        setTimeout(
          function () {
            document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })
          }, 500
        )
      } else if (this.contactClick === true) {
        this.contactClick = false
      }
    },
    handleView () {
      this.mobileView = window.innerWidth <= 500
    },
    getGallery () {
      const vm = this
      vm.gallery = getImages('gallery')
    },
    getReviews () {
      const vm = this
      let reviews = []
      let data = {}
      db.firestore.collection('reviews')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // Using image name to obtain image url
            db.storing.ref('images')
              .child(doc.data().image)
              .getDownloadURL()
              .then((downloadURL) => {
                data = {
                  id: doc.id,
                  name: doc.data().name,
                  image: downloadURL,
                  rating: doc.data().rating,
                  more: doc.data().more,
                  date: doc.data().date
                }
                reviews.push(data)
                reviews = reviews.sort(
                  (a, b) => b.date - a.date
                )
              })
          })
        })

      vm.listReviews = reviews
    }
  }
}
</script>

<style lang="scss">
@import "../assets/common.scss";

.sizeme {
  width: 85%;
  margin: auto;
}

.imageborder {
  box-sizing: border-box;
  border: 1px solid #2c3e50;
}

body {
  background: #fff;
  padding-bottom: 5px;
  @media (max-width: 760px) {
    margin: 0;
    padding-bottom: 75px;
  }
}

a {
    text-decoration: none;
    color: $content-colour;
  }

.button-spacing {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

  @media (min-width: 760px) {
    width: 60%;
  }
}

</style>
