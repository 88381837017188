<template>
  <img
    class="image-layout"
    alt="Mochi Trailers"
    src="../../assets/banner.png"
    v-on:click="homePage()">
</template>

<script>

export default {
  methods: {
    homePage () {
      this.$router.push(
        { name: 'Home' }
      ).catch(() => {})
    }
  }
}

</script>

<style lang="scss" scoped>

.image-layout {
  margin-top: 10px;
  width: 22%;
  cursor: pointer;

  @media (max-width: 760px) {
    margin-top: 5px;
    width: 35%
  }
}

</style>
