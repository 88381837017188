import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { degreeToRad } from './helper'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    rating: '0',
    formFocus: false,
    accessGranted: false,
    username: '',
    env: '',
    listImages: [],
    width: 0,
    interaction: {
      mode: 'b',
      hovering: null,
      mouseX: 1,
      config: {
        b: {
          // How much bigger will the hovering image be than others
          scale: 2.2,
          // duration of transition animation
          duration: 500
        }
      }
    },
    config: {
      radius: 5,
      height: 320,
      degreeSkew: 15,
      border: {
        thickness: 1.5,
        color: '#2c3e50'
      }
    }
  },
  getters: {
    tan: (state) => {
      return Math.tan(degreeToRad(state.config.degreeSkew))
    },
    getMousePoxByPercent: (state) => {
      return state.interaction.mouseX / state.width
    },
    getNumImages: (state) => {
      return state.listImages.length
    },
    getImageByID: (state) => (id) => {
      return state.listImages[id].image
    },
    getContainerOffset: (state, getters) => {
      return state.config.height / 2 * getters.tan
    },
    /**
     * Get the width (in percentage) of the image of given id
     *
     * @return {*} the width in range [0, 1]
     */
    getWidthByID: (state, getters) => (id) => {
      let callback = null

      const mode = state.interaction.mode
      switch (mode) {
        case 'b':
          callback = getters.getWidthByIDModeB
          break
        default:
          return 'Error: Invalid mode: ' + mode
      }

      return callback(id)
    },
    /**
     * Get the width of a image given id (in percentage)
     */
    getWidthByIDModeB: (state, getters) => (id) => {
      const length = state.listImages.length

      // Before width is inited, which will be 1, return 1 / N
      // where N is the number of images
      if (state.width === 0) {
        return 1 / length
      }

      // Total width of this component, to account for the width lost with skewed images on both ends
      const relativeTotalWidth = 1 + (state.config.height * getters.tan / state.width)

      // width, in percentage, of each images
      const width = 1 / length

      // above width taken skew into account
      const relativeWidth = relativeTotalWidth / length

      // id of the image currently hovering over
      const curHovering = state.interaction.hovering
      if (curHovering === null) {
        return relativeWidth
      }

      const scale = state.interaction.config.b.scale

      if (curHovering === id) {
        return relativeWidth * scale
      } else {
        return relativeWidth * ((1 - scale * width) / ((length - 1) * width))
      }
    }
  },
  mutations: {
    setHoverImage ({ interaction }, id) {
      interaction.hovering = id
    },
    setWidth (state, newWidth) {
      state.width = newWidth
    },
    setMousePos (state, newX) {
      state.interaction.mouseX = newX
    },
    setImages (state, listURLImages) {
      state.listImages = listURLImages
    },
    checkAccess: (state, creds) => {
      state.accessGranted = creds
    },
    displayName: (state, userName) => {
      state.username = userName
    },
    checkENV: (state) => {
      if (process.env.NODE_ENV === 'development') {
        state.env = '-dev'
      } else {
        state.env = ''
      }
    },
    checkFormFocus: (state, formFocus) => {
      state.formFocus = formFocus
    },
    checkRating: (state, rating) => {
      state.rating = rating
    }
  },
  actions: {
    init ({ commit }, listImages) {
      commit('setImages', listImages)
    }
  },
  plugins: [createPersistedState()]
})
