import db from '../../config/db'
import axios from 'axios'
import {ref, listAll, getDownloadURL } from "firebase/storage";

function degreeToRad (degree) {
  return degree * Math.PI / 180
}

function getImages (imageRefDB) {
  let gallerys = []
  let data = {}
  const listRef = ref(db.storing,imageRefDB);
  listAll(listRef)
    .then((images) => {
      images.items.forEach((image, i) => {
        getDownloadURL(ref(db.storing, image))
          .then((downloadURL) => {
            data = {
              id: i,
              image: downloadURL
            }
            gallerys.push(data)
            gallerys = gallerys.sort(
              (a, b) => b.id - a.id
            )
          })
      })
    })
  return gallerys
}

function getPrices (sheetId, range) {
  const key = 'AIzaSyDRW_M57IfaR8LCX6Xf4PnyTL4HJwjlBTk'
  const URL = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${key}`

  const jsonValue = []

  axios.get(URL).then(response => {
    jsonValue.push(response.data.values)
  })
  return jsonValue
}

export { degreeToRad, getImages, getPrices }
