import Vue from 'vue'
import { rtdbPlugin } from 'vuefire'
import App from './App.vue'
import router from './router'
import store from './store/store'
import JwPagination from 'jw-vue-pagination'
import 'c-swipe/dist/swipe.css'
import { Swipe, SwipeItem } from 'c-swipe'
import LoadScript from 'vue-plugin-load-script'

Vue.config.productionTip = false

Vue.component('jw-pagination', JwPagination)
Vue.component('swipe', Swipe)
Vue.component('swipe-item', SwipeItem)

Vue.use(LoadScript)

new Vue({
  rtdbPlugin,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
