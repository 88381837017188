import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Trailers from '../views/Trailers.vue'
import AboutUs from '../views/AboutUs.vue'
import ContactUs from '../views/ContactUs.vue'
import AutoCruiser from '../views/sub/AutoCruiser.vue'
import Lightweight from '../views/sub/Lightweight.vue'
import Flatbed from '../views/sub/Flatbed.vue'
import Galaxy from '../views/sub/Galaxy.vue'
import RL7000 from '../views/sub/RL7000.vue'
import RL5000 from '../views/sub/RL5000.vue'
import Standard from '../views/sub/Standard.vue'
import Widebody from '../views/sub/Widebody.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trailers',
    name: 'Trailer',
    component: Trailers
  },
  {
    path: '/auto-cruiser',
    name: 'AutoCruiser',
    component: AutoCruiser
  },
  {
    path: '/flatbed',
    name: 'Flatbed',
    component: Flatbed
  },
  {
    path: '/galaxy',
    name: 'Galaxy',
    component: Galaxy
  },
  {
    path: '/lightweight',
    name: 'Lightweight',
    component: Lightweight
  },
  {
    path: '/rl7000',
    name: 'RL7000',
    component: RL7000
  },
  {
    path: '/rl5000',
    name: 'RL5000',
    component: RL5000
  },
  {
    path: '/standard',
    name: 'Standard',
    component: Standard
  },
  {
    path: '/widebody',
    name: 'Widebody',
    component: Widebody
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
