<template>
  <div class="card-body">
    <form id="form" @submit.prevent="sentEmail">
      <div class="form-group">
        <label style="margin-top: 15px;">Full Name:</label>
        <input
          required
          type="text"
          v-model="form.name"
        />
        <label>Email Address:</label>
        <input
          required
          type="email"
          v-model="form.email_address"
        />
        <label>Telephone Number:</label>
        <input
          required
          type="text"
          v-model="form.telephone_number"
        />
        <label>Enquiring about:</label>
        <div class="radioLayout">
          <input
            type="radio"
            id="private"
            name="enquire"
            value="Private Car"
            v-model="form.enquiry"
            required
          >
          <label for="private">Private Car</label>
          <input
            type="radio"
            id="trade"
            name="enquire"
            value="Trade Car"
            v-model="form.enquiry"
          >
          <label for="trade">Trade Car</label>
          <input
            type="radio"
            id="towing"
            name="enquire"
            value="Towing Service"
            v-model="form.enquiry"
          >
          <label for="towing">Towing Service</label>
          <input
            type="radio"
            id="other"
            name="enquire"
            value="Other"
            v-model="form.enquiry"
          >
          <label for="other">Other</label>
        </div>
        <label>Collection Address:</label>
        <input
          required
          type="text"
          v-model="form.collection"
        />
        <label>Delivery Address:</label>
        <input
          required
          type="text"
          v-model="form.delivery"
        />
        <p>What's the time frame you like the vehicle moved?</p>
        <div class="radioLayout">
          <input
            type="radio"
            id="flexible"
            name="move"
            value="Flexible"
            v-model="form.moved"
            required
          >
          <label for="flexible">Flexible</label>
          <input
            type="radio"
            id="inflexible"
            name="move"
            value="Inflexible"
            v-model="form.moved"
          >
          <label for="inflexible">Inflexible</label>
          <input
            type="radio"
            id="urgent"
            name="move"
            value="Urgent"
            v-model="form.moved"
          >
          <label for="urgent">Urgent (within 48 hours)</label>
        </div>
        <p style="margin-top: 10px;">
          *Urgent/Inflexible deliveries are more expensive.
          Being as flexible as possible will save you money.*
        </p>
        <label>Additional Comments:</label>
        <textarea
          wrap="hard"
          style="resize: none;"
          placeholder="Add description here"
          v-model="form.comments"
        >
        </textarea>
      </div>
      <div class="form-group">
        <input
          type="submit"
          class="button-icon"
          value="Submit Request"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  data () {
    return {
      form: {
        name: '',
        email_address: '',
        telephone_number: '',
        enquiry: '',
        collection: '',
        delivery: '',
        moved: '',
        comments: ''
      }
    }
  },
  mounted () {
    const form = document.getElementById('form')

    form.addEventListener('focus', (event) => {
      this.$store.commit('checkFormFocus', true)
      // event.target.style.background = 'pink'
    }, true)

    form.addEventListener('blur', (event) => {
      this.$store.commit('checkFormFocus', false)
      // event.target.style.background = ''
    }, true)
  },
  methods: {
    notication (text, icon) {
      Swal.fire({
        title: text,
        icon: icon,
        timer: 3000,
        showConfirmButton: false
      })
    },
    sentEmail () {
      const vm = this
      this.$loadScript('https://smtpjs.com/v3/smtp.js')
        .then(() => {
          window.Email && window.Email.send({
            SecureToken: '3d7894bf-9a18-4ab6-aec4-5ce4ff1c3873',
            From: 'mosvehicletransport@gmail.com',
            To: 'mosvehicletransport@gmail.com',
            Subject: 'Quote for Transportation',
            Body: `<p>Hello Mo.</P
            <p>My name is ${this.form.name} and I am emailing about ${this.form.enquiry} service,
            my number is ${this.form.telephone_number} and my Email is ${this.form.email_address}.</p>
            <p>The transport would be from ${this.form.collection} and to
            ${this.form.delivery}, I am ${this.form.moved} with when I need to service.</p>
            <p>Additional Info:</p>
            <p>${this.form.comments}</p>

            <p>Thank you. </p>`
          })
            .then(function (message) {
              vm.form = {}
              vm.notication("Thank you for inquiring with Mo's Vehicle Transport", 'success')
            })
        })
        .catch((e) => {
          vm.notication('Error has occcured', 'error')
          console.log(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./form.scss";

</style>
